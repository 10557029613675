.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.exclude {
  display: none;
}

.direction:hover .exclude {
  display: inline;
}

.updown {
  display: none;
  cursor: pointer;
}

.listitem:hover .updown {
  display: inline;
}

.favorite-button {
  display: inline;
}

.dropdown {
  width: 300px;
  display: inline;
}

.roaddropdown {
  width: 200px;
  display: inline;
}

.privacy-link {
  color: #4183c4;
}

.privacy .privacy-text {
  display: none;
}

.privacy:hover .privacy-text {
  display: inline;
}

.invalidroadexprires {
  background-color: red;
}
#map {
  display: inline;
  width: 64px;
  height: 40px;
  margin-top: -16px;
  vertical-align: bottom;
  position:relative;
}

.options {
  width: 200px;
  display: inline;
}

#video {
  border: 1px solid #999;
  width: 98%;
  max-width: 860px;
}

canvas {
  border: 1px solid #999;
}

#portal2 {
}

#cropped {
  filter: contrast(200%) grayscale(100%);
}

#currentzone {
  filter: contrast(200%) grayscale(100%);
}

#portal {
  filter: contrast(200%) grayscale(100%);
}

.error {
  color: red;
}

.warn {
  color: orange;
}

.info {
  color: darkgreen;
}

.xbutton {
  text-align: center;
}

.status {
  font-size: 20px;
  color: blue;
}